export const isLessonComplete = (progress, themeId, questionId, lessonId) => {
  const lessonProgress =
    progress[themeId].questions[questionId] &&
    progress[themeId].questions[questionId].lessons[lessonId];
  return lessonProgress && lessonProgress.complete;
};

export const getQuestionStatus = (progress, themeId, question) => {
  // Returns if completed and a lesson complete count

  // Check Contentful question data against progress in Firebase
  const completedLessons = question.lessons.filter(lesson => {
    const lessonProgress =
      progress[themeId].questions[question.contentful_id] &&
      progress[themeId].questions[question.contentful_id].lessons[
        lesson.contentful_id
      ];
    return lessonProgress && lessonProgress.complete;
  });

  return {
    complete: completedLessons.length === question.lessons.length,
    lessonCompleteCount: completedLessons.length,
  };
};

export const getThemeStatus = (progress, theme) => {
  // Returns if completed and a question complete count

  // Check Contentful theme data against progress in Firebase
  // Get each questions status
  const themeQuestions = [];
  theme.questions.map(question => {
    const questionStatus = getQuestionStatus(
      progress,
      theme.contentful_id,
      question
    );
    return themeQuestions.push(questionStatus);
  });

  const completedQuestions = themeQuestions.filter(question => {
    return question.complete;
  });

  return {
    complete: completedQuestions.length === themeQuestions.length,
    questionCompleteCount: completedQuestions.length,
  };
};
