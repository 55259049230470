import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SignedIn, SignedOut, useSignIn, useClerk } from '@clerk/clerk-react';
import styled from 'styled-components';
import {
  buttonStyles,
  fontSize,
  fontWeights,
  inputStyles,
  minBreakpointQuery,
  visuallyHidden,
} from '../styles';
import { Button, Container, Heading } from './ui';
import { SiteContext } from '../context/SiteContext';

const StyledLoginForm = styled.section``;

const StyledForm = styled.form`
  text-align: center;
  max-width: 375px;
  margin: 100px auto 0;
`;

const StyledLoginText = styled.p`
  margin-top: 20px;

  ${minBreakpointQuery.mlarge`
    ${fontSize(18)}
  `}
`;

const StyledFieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 20px 0;
`;

const StyledField = styled.label`
  display: block;
  margin: 10px 0;
`;

const StyledLabel = styled.span`
  ${visuallyHidden()};
`;

const StyledInput = styled.input`
  ${inputStyles(true)}
`;

const StyledButton = styled.button`
  ${buttonStyles()}
`;

const StyledContact = styled.p`
  margin-top: 25px;

  ${minBreakpointQuery.mlarge`
    ${fontSize(18)}
    margin-top: 35px;
  `}

  a {
    font-weight: ${fontWeights.semibold};
    text-decoration: underline;
  }
`;

const StyledSignOutButton = styled(Button)`
  margin-top: 30px;
`;

const LoginForm = () => {
  const {
    contentfulContactInformation: { emailAddress },
  } = useStaticQuery(graphql`
    query LoginFormQuery {
      contentfulContactInformation {
        emailAddress
      }
    }
  `);

  const { isLoaded, signIn } = useSignIn();
  const { signOut } = useClerk();

  const { login, logout } = useContext(SiteContext);

  const handleSubmit = async event => {
    const formData = new FormData(event.target);
    const emailAddress = formData.get('email');
    const password = formData.get('password');

    event.preventDefault();

    try {
      const response = await signIn.create({
        identifier: emailAddress,
        password,
      });
      if (response.status === 'complete') {
        await login(emailAddress);
        window.location.reload();
      }
    } catch (error) {
      // Display error to user
      console.log(error.errors);
    }
  };

  if (!isLoaded) {
    // Handle loading state
    return null;
  }

  return (
    <StyledLoginForm>
      <Container>
        <SignedOut>
          <StyledForm onSubmit={e => handleSubmit(e)}>
            <Heading>Login</Heading>
            <StyledLoginText>Please sign in to continue</StyledLoginText>
            <StyledFieldset>
              <StyledField>
                <StyledLabel>Email</StyledLabel>
                <StyledInput
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  required
                />
              </StyledField>
              <StyledField>
                <StyledLabel>Password</StyledLabel>
                <StyledInput
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  required
                />
              </StyledField>
            </StyledFieldset>
            <StyledButton>Sign in</StyledButton>
            <StyledContact>
              Don't have an account?{' '}
              <a href={`mailto:${emailAddress}`}>Contact us.</a>
            </StyledContact>
          </StyledForm>
        </SignedOut>
        <SignedIn>
          <StyledSignOutButton
            onClick={async () => {
              signOut();
              await logout();
              window.location.reload();
            }}
          >
            Sign out
          </StyledSignOutButton>
        </SignedIn>
      </Container>
    </StyledLoginForm>
  );
};

export default LoginForm;
