import React, { useContext, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  brandColours,
  fontSize,
  maxBreakpointQuery,
  minBreakpointQuery,
  standardColours,
  visuallyHidden,
} from '../styles';
import { getQuestionStatus } from '../utils';
import { SiteContext } from '../context/SiteContext';

const StyledCurriculum = styled.div`
  margin-top: 20px;
  border: 1px solid ${brandColours.duodenary};

  ${minBreakpointQuery.medium`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: 30px;
  `}
`;

const StyledDeskopHeadings = styled.div`
  display: none;

  ${minBreakpointQuery.smedium`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${brandColours.duodenary};
  `}
`;

const StyledDeskopHeading = styled.h4`
  padding: 20px;
  cursor: pointer;
  background-color: ${({ display }) =>
    display ? brandColours.tertiary : brandColours.nonary};
  flex-grow: 1;
  text-align: center;
  border-left: 1px solid ${brandColours.duodenary};

  ${minBreakpointQuery.medium`
    ${fontSize(18)}
  `}

  &:first-child {
    border: none;
  }
`;

const StyledTheme = styled.div``;

const StyledHeading = styled.h4`
  padding: 15px;
  cursor: pointer;
  background-color: ${({ display }) =>
    display ? brandColours.tertiary : brandColours.nonary};
  border-bottom: 1px solid ${brandColours.duodenary};

  ${minBreakpointQuery.smedium`
    display: none;
  `}
`;

const StyledQuestions = styled.ul`
  border-bottom: 1px solid ${brandColours.duodenary};
  ${({ display }) => {
    if (!display) {
      return css`
        ${visuallyHidden()};
      `;
    }
  }}
`;

const StyledQuestion = styled.li`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  background-color: ${brandColours.nonary};
  padding: 15px 15px 15px 50px;
  border-bottom: 1px solid ${brandColours.duodenary};

  ${minBreakpointQuery.medium`
    ${fontSize(18)}
    padding: 22px 25px 22px 70px;
  `}

  &:last-child {
    border: none;
  }
`;

const StyledTick = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50px;
  border-right: 1px solid ${brandColours.duodenary};

  ${minBreakpointQuery.medium`
    width: 70px;
  `}

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: ${({ complete }) =>
      complete ? brandColours.tertiary : brandColours.duodenary};
    border-radius: 50%;
    width: 25px;
    height: 25px;
    transform: translate(-50%, -50%);

    ${minBreakpointQuery.medium`
      width: 35px;
      height: 35px;
    `}
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 38%;
    height: 14px;
    width: 8px;
    border: 4px solid ${standardColours.black};
    border-top: none;
    border-left: none;
    opacity: ${({ complete }) => (complete ? 1 : 0)};
    transform: rotate(45deg) translate(-50%, -50%);

    ${minBreakpointQuery.medium`
      height: 16px;
      width: 10px;
      left: 40%;
    `}
  }

  span {
    ${visuallyHidden()};
  }
`;

const StyledQuestionTitle = styled.p`
  margin-left: 15px;

  ${minBreakpointQuery.medium`
    margin-left: 25px;
  `}
`;

const StyledExtraInfo = styled.p`
  ${fontSize(14)}

  ${maxBreakpointQuery.medium`
    display: none;
  `}
`;

const Curriculum = () => {
  const {
    allContentfulTheme: { nodes },
  } = useStaticQuery(graphql`
    query CurriculumQuery {
      allContentfulTheme(sort: { order: DESC, fields: title }) {
        nodes {
          themeId: contentful_id
          title
          questions {
            contentful_id
            title
            lessons {
              contentful_id
            }
          }
        }
      }
    }
  `);
  const { progress } = useContext(SiteContext);

  const [activeItem, setActiveItem] = useState(0);

  return (
    <StyledCurriculum>
      <StyledDeskopHeadings>
        {nodes.map(({ title }, i) => (
          <StyledDeskopHeading
            key={i}
            display={activeItem === i}
            onClick={() => setActiveItem(i)}
          >
            {title}
          </StyledDeskopHeading>
        ))}
      </StyledDeskopHeadings>
      {Object.keys(progress).length > 0 &&
        nodes.map(({ themeId, title, questions }, i) => {
          const display = activeItem === i;
          return (
            <StyledTheme key={i}>
              <StyledHeading display={display} onClick={() => setActiveItem(i)}>
                {title}
              </StyledHeading>
              <StyledQuestions display={display}>
                {questions.map(({ title, contentful_id, lessons }) => {
                  const questionStatus = getQuestionStatus(progress, themeId, {
                    contentful_id,
                    lessons,
                  });

                  return (
                    <StyledQuestion>
                      <StyledTick complete={questionStatus.complete}>
                        <span>
                          {questionStatus.complete
                            ? 'Complete'
                            : 'Not Complete'}
                        </span>
                      </StyledTick>
                      <StyledQuestionTitle>{title}</StyledQuestionTitle>
                      {questionStatus.lessonCompleteCount > 0 && (
                        <StyledExtraInfo>
                          {questionStatus.complete ? (
                            <strong>Complete</strong>
                          ) : (
                            <span>
                              <strong>
                                {questionStatus.lessonCompleteCount}
                              </strong>{' '}
                              of {lessons.length} lessons complete
                            </span>
                          )}
                        </StyledExtraInfo>
                      )}
                    </StyledQuestion>
                  );
                })}
              </StyledQuestions>
            </StyledTheme>
          );
        })}
    </StyledCurriculum>
  );
};

export default Curriculum;
