import React, { useContext, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  brandColours,
  fontSize,
  fontWeights,
  maxBreakpointQuery,
  minBreakpointQuery,
  standardColours,
  standardTransition,
  visuallyHidden,
  zIndexLayers,
} from '../styles';
import { Button, Container, HtmlContent, Link, Svg, Video } from './ui';
import { slugify } from '../utils';
import { SiteContext } from '../context/SiteContext';
import Curriculum from './Curriculum';
import inProgressIcon from '../images/in-progress-icon.inline.svg';
import lockIcon from '../images/lock-icon.inline.svg';
import checkIcon from '../images/check-icon.inline.svg';

const StyledAccount = styled.section`
  margin-top: -50px;
`;

const StyledWrapper = styled.div`
  ${minBreakpointQuery.mlarge`
    display: flex;
    align-items: start;
  `}
`;

const StyledSidebar = styled.div`
  background-color: ${standardColours.white};
  display: flex;
  align-items: center;
  border: 1px solid ${brandColours.duodenary};

  ${maxBreakpointQuery.mlarge`
    flex-wrap: wrap;
  `}

  ${minBreakpointQuery.mlarge`
    max-width: 180px;
    flex-direction: column;
  `}

  ${minBreakpointQuery.large`
     max-width: 220px;
  `}
`;

const StyledImage = styled.img`
  max-width: 100px;

  ${maxBreakpointQuery.mlarge`
    border-right: 1px solid ${brandColours.duodenary};
  `}

  ${minBreakpointQuery.medium`
     max-width: 178px;
  `}

  ${minBreakpointQuery.large`
     max-width: 218px;
  `}
`;

const StyledName = styled.p`
  flex: 1;
  padding: 10px;
  font-weight: ${fontWeights.semibold};

  ${minBreakpointQuery.mlarge`
    border-top: 1px solid ${brandColours.duodenary};
  `}

  ${minBreakpointQuery.large`
    padding: 20px;
    ${fontSize(18)}
  `}
`;

const StyledInfo = styled.p`
  border-top: 1px solid ${brandColours.duodenary};
  padding: 10px;
  ${fontSize(14)}
  line-height: 1.6;

  ${minBreakpointQuery.large`
    padding: 20px;
  `}
`;

const StyledBadgeContent = styled.div`
  border-top: 1px solid ${brandColours.duodenary};
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${minBreakpointQuery.small`
    flex-direction:row;
  `}

  ${minBreakpointQuery.mlarge`
    padding: 20px;
    flex-direction:column;
  `}
`;

const StyledBadge = styled.img`
  width: 160px;
`;

const StyledGatsbyBadge = styled(GatsbyImage)``;

const StyledDownloadWrapper = styled.div`
  ${minBreakpointQuery.small`
    margin-left: 10px;
    margin-top: 18px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-left: 0px;
  `}
`;

const StyledBadgeHeading = styled.h2`
  margin-bottom: 14px;
  ${fontSize(20)};
`;

const StyledBadgeText = styled.p``;

const StyledDownload = styled.a`
  display: block;
  margin-top: 28px;
  text-decoration: underline;
  text-underline-offset: 4px;
`;

const StyledMainContent = styled.div`
  margin-top: 20px;

  ${minBreakpointQuery.medium`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-left: 20px;
    margin-top: 75px;
  `}

  ${minBreakpointQuery.large`
     margin-left: 30px;
  `}

  ${minBreakpointQuery.xlarge`
     margin-left: 40px;
  `}
`;

const StyledIntro = styled(HtmlContent)`
  h2 {
    font-weight: ${fontWeights.regular};
  }
`;

const StyledSteps = styled.div`
  margin-top: 20px;
  display: flex;

  ${maxBreakpointQuery.smedium`
    overflow: scroll;
  `}

  ${minBreakpointQuery.smedium`
    justify-content: space-between;
    width: calc(100% - 15px);
  `}

  ${minBreakpointQuery.medium`
    margin-top: 25px;
  `}
`;

const StyledStep = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0;
  background-color: ${({ complete, active }) =>
    complete
      ? brandColours.secondary
      : active
      ? brandColours.tertiary
      : brandColours.nonary};
  color: ${({ complete, disabled }) =>
    complete
      ? standardColours.white
      : disabled
      ? brandColours.duodenary
      : brandColours.secondary};
  border: 0;
  border-top: 1px solid ${brandColours.duodenary};
  border-bottom: 1px solid ${brandColours.duodenary};
  ${fontSize(18)}
  font-weight: ${fontWeights.semibold};
  flex-shrink: 0;
  padding: 15px 4px 15px 30px;

  ${minBreakpointQuery.smedium`
    flex-grow: 1;
    justify-content: space-between;
  `}

  ${minBreakpointQuery.medium`
    ${fontSize(20)}
  `}

  &:first-child {
    border-left: 1px solid ${brandColours.duodenary};
    padding: 15px 4px 15px 22px;
  }

  &:after,
  :before {
    content: '';
    border-top: 28px solid transparent;
    border-bottom: 28px solid transparent;
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    top: 50%;
    right: -18px;
    transform: translate(-50%, -50%);
    z-index: ${zIndexLayers.first};
  }

  &:before {
    right: -19px;
    border-left: 12px solid
      ${({ complete }) =>
        complete ? brandColours.secondary : brandColours.duodenary};
  }

  &:after {
    border-left: 12px solid
      ${({ active, complete }) =>
        complete
          ? brandColours.secondary
          : active
          ? brandColours.tertiary
          : brandColours.nonary};
    z-index: ${zIndexLayers.second};
  }
`;

const StyledStepIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background: ${standardColours.transparentBlack(0.1)};
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-left: 10px;

  ${minBreakpointQuery.medium`
    margin-left: 15px;
    width: 30px;
    height: 30px;
    padding: 5px;
  `}
`;

const StyledStepIcon = styled(Svg)``;

const StyledStepContent = styled.div`
  margin-top: 20px;

  ${minBreakpointQuery.medium`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: 30px;
  `}
`;

const StyledContent = styled.div`
  ${({ display }) => {
    if (!display) {
      return css`
        ${visuallyHidden()};
      `;
    }
  }}
`;

const StyledContentIntro = styled.div`
  ${minBreakpointQuery.medium`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

const StyledLink = styled(Link)`
  display: block;
  text-decoration: underline;
  ${fontSize(12)}

  ${maxBreakpointQuery.medium`
    margin: 10px 0;
  `}
`;

const StyledHtmlContent = styled(HtmlContent)``;

const StyledCheckboxes = styled.div``;

const StyledLabel = styled.label`
  line-height: 24px;
  display: block;
  margin-top: 15px;
  cursor: pointer;
`;

// moved up for checked
const StyledCheckboxText = styled.span`
  position: relative;
  display: block;
  padding-left: 30px;
  ${fontSize(14)};

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }

  &:before {
    left: 0;
    height: 24px;
    width: 24px;
    border: 1px solid ${standardColours.black};
    background-color: ${brandColours.nonary};
  }

  &:after {
    left: 6px;
    top: 12px;
    height: 6px;
    width: 12px;
    border: 2px solid ${standardColours.black};
    border-top: 0;
    border-right: 0;
    opacity: 0;
    transform: translateY(calc(-50% - 2px)) rotate(-45deg);
    transition: ${standardTransition('opacity')};
  }
`;

const StyledCheckbox = styled.input`
  ${visuallyHidden()};

  &:checked + ${StyledCheckboxText} {
    &:before {
      background-color: ${brandColours.tertiary};
    }
    &:after {
      opacity: 1;
    }
  }
`;

const StyledGallery = styled.section``;

const StyledGalleryHeading = styled.h2`
  ${fontSize(24)};
  margin-top: 40px;
`;

const StyledGalleryText = styled.p`
  margin-top: 20px;
`;

const StyledItems = styled.div`
  display: grid;
  margin-top: 40px;
  justify-content: center;
  gap: 12px;

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.xlarge`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledImageWrapper = styled.div`
  flex-shrink: 0;
  height: 210px;
  width: 278px;
`;

const StyledTextWrapper = styled.div`
  margin: 20px 0;
  line-height: 1.4;
`;

const StyledText = styled.p``;

const StyledCategory = styled.span`
  font-weight: ${fontWeights.bold};
  display: block;
  margin-top: 10px;
  ${fontSize(16)};
`;

const StyledItemImage = styled.img``;

const StyledVideo = styled(Video)`
  height: 210px;
  width: 278px;

  #button {
    bottom: 0px;
    left: 0px;
    scale: 0.6;
  }
`;

const StyledPagination = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 10px;
  justify-content: center;

  ${minBreakpointQuery.medium`
    justify-content: flex-end;
  `}
`;

const StyledPaginationButton = styled(Button)`
  width: 40px;
  height: 40px;
  padding: 0;
  display: block;
  ${({ isActive }) => {
    if (isActive) {
      return css`
        background-color: ${brandColours.tertiary};
      `;
    } else {
      return css`
        background-color: ${brandColours.octonary};
      `;
    }
  }}
`;

const Account = () => {
  const {
    contentfulAccount: {
      stepIntro,
      step1Intro,
      step2Intro,
      step4Intro,
      step5Intro,
    },
    contentfulDigitalBadge: {
      heading,
      image: { gatsbyImageData, mimeType, title, url },
      text,
    },
  } = useStaticQuery(graphql`
    query AccountQuery {
      contentfulAccount {
        stepIntro {
          raw
        }
        step1Intro {
          raw
        }
        step2Intro {
          raw
        }
        step4Intro {
          raw
        }
        step5Intro {
          raw
        }
      }
      contentfulStep1Progress {
        fields {
          steps {
            id
            name
          }
        }
      }
      contentfulStep2Progress {
        fields {
          steps {
            id
            name
          }
        }
      }
      contentfulDigitalBadge {
        heading
        image {
          gatsbyImageData(width: 160, height: 160)
          mimeType
          title
          url
        }
        text
      }
    }
  `);

  const {
    school: {
      fields: {
        logo,
        name,
        information,
        step1Progress,
        step2Progress,
        gallery,
      },
    },
    progress,
    userId,
    completeStepProgress,
  } = useContext(SiteContext);

  // remove school name from progress
  step1Progress &&
    step1Progress.fields &&
    delete step1Progress.fields.schoolName;
  step2Progress &&
    step2Progress.fields &&
    delete step2Progress.fields.schoolName;

  const defaultStep =
    progress.step2Progress && progress.step2Progress.every(f => f === true)
      ? 3
      : progress.step1Progress && progress.step1Progress.every(f => f === true)
      ? 2
      : 1;

  const [activeStep, setActiveStep] = useState(defaultStep);

  const steps = [
    {
      id: 1,
      content: step1Intro,
      progress:
        progress && progress.step1Progress
          ? [
              {
                id: 0,
                name: 'Communicate with the wider School Community',
                value: progress.step1Progress[0],
              },
              {
                id: 1,
                name: 'Carry out Student Survey',
                value: progress.step1Progress[1],
              },
              {
                id: 2,
                name: 'Carry out Staff Survey',
                value: progress.step1Progress[2],
              },
              {
                id: 3,
                name: 'Share Nature Connection Survey with School',
                value: progress.step1Progress[3],
              },
            ]
          : [],
    },
    {
      id: 2,
      content: step2Intro,
      progress:
        progress && progress.step2Progress
          ? [
              {
                id: 0,
                name: 'Explore Staff Training Opportunities',
                value: progress.step2Progress[0],
              },
              {
                id: 1,
                name: 'Carry out Curriculum Mapping of Sustainability',
                value: progress.step2Progress[1],
              },
              {
                id: 2,
                name: 'Opportunities to engage with External Organisations',
                value: progress.step2Progress[2],
              },
              {
                id: 3,
                name: 'Explore Sustainability Awards / Badges / Flags',
                value: progress.step2Progress[3],
              },
              {
                id: 4,
                name: 'Map School Grounds as a Curriculum Resource',
                value: progress.step2Progress[4],
              },
            ]
          : [],
    },
    {
      id: 3,
    },
    {
      id: 4,
      content: step4Intro,
    },
    {
      id: 5,
      content: step5Intro,
    },
  ];

  const shown = 6;

  const [isActive, setIsActive] = useState(false);
  const [displayedItems, setDisplayedItems] = useState(
    gallery ? gallery.fields.galleryItems.slice(0, shown) : false
  );

  const showMore = amount => {
    setDisplayedItems(
      gallery.fields.galleryItems &&
        gallery.fields.galleryItems.slice(amount - shown, amount)
    );
  };

  const getPagination = () => {
    const totalItems = gallery.fields.galleryItems.length;
    const amount = totalItems / shown;
    let paginationArray = [];

    for (let i = 0; i < amount; i++) {
      paginationArray.push({ index: i + 1, amount: shown * (i + 1) });
    }

    return paginationArray;
  };

  useEffect(() => {
    setActiveStep(defaultStep);
  }, [progress]);

  return (
    <StyledAccount>
      <Container>
        <StyledWrapper>
          <StyledSidebar>
            {logo && (
              <StyledImage
                src={logo.fields.file.url.replace('//', 'https://')}
                alt={logo.fields.title}
              />
            )}
            <StyledName>{name}</StyledName>
            {information && <StyledInfo>{information}</StyledInfo>}
            <StyledBadgeContent>
              {mimeType === 'image/svg+xml' ? (
                <StyledBadge src={url} loading="lazy" aria-label={title} />
              ) : (
                <StyledGatsbyBadge
                  image={gatsbyImageData}
                  alt={title}
                  objectFit="cover"
                />
              )}
              <StyledDownloadWrapper>
                <StyledBadgeHeading>{heading}</StyledBadgeHeading>
                {text && <StyledBadgeText>{text}</StyledBadgeText>}
                <StyledDownload href={url} target="_blank" rel="noopener">
                  Download
                </StyledDownload>
              </StyledDownloadWrapper>
            </StyledBadgeContent>
          </StyledSidebar>
          <StyledMainContent>
            <StyledIntro richText={stepIntro} />
            <StyledSteps>
              {steps.map(({ id }) => {
                const complete = id < defaultStep;
                const locked =
                  id === 1
                    ? false
                    : (id === 2 && defaultStep === 1) ||
                      (id === 3 && defaultStep <= 2) ||
                      (id > 3 && defaultStep < 3);

                return (
                  <StyledStep
                    key={id}
                    active={activeStep === id}
                    complete={complete}
                    onClick={() => {
                      setActiveStep(id);
                    }}
                    disabled={locked}
                  >
                    <span>Step {id}</span>
                    <StyledStepIconWrapper>
                      <StyledStepIcon
                        image={
                          complete
                            ? checkIcon
                            : locked
                            ? lockIcon
                            : inProgressIcon
                        }
                      />
                    </StyledStepIconWrapper>
                  </StyledStep>
                );
              })}
            </StyledSteps>
            <StyledStepContent>
              {steps.map(({ id, content, progress }) => {
                const display = activeStep === id;

                if (id === 3) {
                  return (
                    <StyledContent key={id} display={display}>
                      <StyledContentIntro>
                        <h3>The MEE Curriculum</h3>
                        <StyledLink to="/curriculum/">
                          View Curriculum
                        </StyledLink>
                      </StyledContentIntro>
                      <Curriculum />
                    </StyledContent>
                  );
                } else {
                  return (
                    <StyledContent key={id} display={display}>
                      <StyledHtmlContent richText={content} />
                      {progress && progress.length > 0 && (
                        <StyledCheckboxes>
                          {progress.map(({ id, name, value }) => {
                            return (
                              <StyledLabel>
                                <StyledCheckbox
                                  id={id}
                                  name={slugify(name)}
                                  type="checkbox"
                                  disabled={value}
                                  defaultChecked={value}
                                  onChange={() =>
                                    completeStepProgress(
                                      userId,
                                      activeStep === 1
                                        ? 'step1Progress'
                                        : 'step2Progress',
                                      id
                                    )
                                  }
                                />
                                <StyledCheckboxText>{name}</StyledCheckboxText>
                              </StyledLabel>
                            );
                          })}
                        </StyledCheckboxes>
                      )}
                    </StyledContent>
                  );
                }
              })}
            </StyledStepContent>
            {displayedItems && (
              <>
                <StyledGallery>
                  <StyledGalleryHeading>
                    {gallery.fields.title}
                  </StyledGalleryHeading>
                  <StyledGalleryText>
                    {gallery.fields.heading}
                  </StyledGalleryText>
                  <StyledItems>
                    {displayedItems.map(
                      (
                        {
                          fields: {
                            caption,
                            image: {
                              fields: {
                                description,
                                file: { url },
                              },
                            },
                            category: {
                              fields: { title },
                            },
                            video,
                          },
                        },
                        i
                      ) => {
                        return (
                          <StyledItem>
                            <StyledImageWrapper>
                              {video ? (
                                <StyledVideo
                                  image={url}
                                  url={video}
                                  title={description}
                                />
                              ) : (
                                <StyledItemImage
                                  src={url}
                                  loading="lazy"
                                  alt={description}
                                  width={278}
                                  height={210}
                                />
                              )}
                            </StyledImageWrapper>
                            <StyledTextWrapper>
                              <StyledText>{caption}</StyledText>
                              <StyledCategory>{`Category: ${title}`}</StyledCategory>
                            </StyledTextWrapper>
                          </StyledItem>
                        );
                      }
                    )}
                  </StyledItems>
                </StyledGallery>
                <StyledPagination>
                  {getPagination().map(({ index, amount }, i) => {
                    const display = isActive ? isActive === i : i === 0;

                    return (
                      <>
                        <StyledPaginationButton
                          onClick={() => {
                            showMore(amount);
                            setIsActive(display ? undefined : i);
                          }}
                          isActive={display}
                        >
                          {index}
                        </StyledPaginationButton>
                      </>
                    );
                  })}
                </StyledPagination>
              </>
            )}
          </StyledMainContent>
        </StyledWrapper>
      </Container>
    </StyledAccount>
  );
};

export default Account;
