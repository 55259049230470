import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SignedIn } from '@clerk/clerk-react';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import Account from '../components/Account';
import LoginForm from '../components/LoginForm';

const AccountPage = () => {
  const {
    contentfulSiteData: { siteName },
    contentfulAccount: { title, heading, metaDescription },
  } = useStaticQuery(graphql`
    query AccountPageQuery {
      contentfulSiteData {
        siteName
      }
      contentfulAccount {
        title
        heading {
          raw
        }
        metaDescription
      }
    }
  `);

  return (
    <Layout title={title} description={metaDescription} siteName={siteName}>
      <main>
        <Banner heading={heading || title} addMargin={true} />
        <SignedIn>
          <Account />
        </SignedIn>
        <LoginForm />
      </main>
    </Layout>
  );
};

export default AccountPage;
